/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    h3: "h3",
    ul: "ul",
    li: "li",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "44bits 팟캐스트 126번째 로그에서는 도커 서울 밋업 종료, 파이어폭스 사용자 감소, 스택오버플로 2021 설문조사에 대해서 이야기를 나누었습니다."), "\n", React.createElement(_components.h3, null, "Dockerfile heardoc 지원"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.docker.com/blog/engineering-update-buildkit-0-9-and-docker-buildx-0-6-releases/"
  }, "Engineering Update: BuildKit 0.9 and Docker Buildx 0.6 Releases - Docker Blog")), "\n"), "\n", React.createElement(_components.h3, null, "스택오버플로 2021 설문조사 결과"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://insights.stackoverflow.com/survey/2021"
  }, "Stack Overflow Developer Survey 2021 - Stack Overflow")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://news.hada.io/topic?id=4765"
  }, "스택오버플로우의 개발자 설문조사 2021 - GeekNews")), "\n"), "\n", React.createElement(_components.h3, null, "Firefox 사용자 감소"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://data.firefox.com/dashboard/user-activity"
  }, "Firefox Public Data Report")), "\n"), "\n", React.createElement(_components.h3, null, "RenderingNG"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://meetup.toast.com/posts/293"
  }, "Chromium이 발표한 RenderingNG가 무엇인가? - NHN Cloud Meetup")), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
